var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "d-flex align-items-center",
    },
    [
      _c("img", {
        staticClass: "hb-image",
        attrs: {
          src: _vm.consultant.image,
          alt: "",
        },
      }),
      _c("div", {}, [
        _c(
          "span",
          {
            staticClass: "txt-evaluation-classtime d-flex align-items-center",
          },
          [
            _c("div", {
              staticClass: "icon-evaluation",
            }),
            _c("div", [_vm._v(_vm._s(_vm.time))]),
          ]
        ),
        _c(
          "span",
          {
            staticClass: "txt-evaluation-hbname d-flex align-items-center",
          },
          [
            _c("div", {
              staticClass: "icon-evaluation icon-evaluation-circle",
            }),
            _c("div", [_vm._v(_vm._s(_vm.consultant.name))]),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }