var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "panel-evaluation-info-right col-lg-6 col-5",
    },
    [
      _c(
        "div",
        _vm._l(_vm.score_description_left, function (data, index) {
          return _c(
            "div",
            {
              key: data.score,
              staticClass: "score d-flex align-items-center",
            },
            [
              _c("div", {
                staticClass: "score-icon",
              }),
              _c(
                "div",
                {
                  staticClass: "score-text",
                },
                [_vm._v(_vm._s(data.score) + _vm._s(data.description))]
              ),
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          staticClass: "scores-low",
        },
        _vm._l(_vm.score_description_right, function (data, index) {
          return _c(
            "div",
            {
              key: data.score,
              staticClass: "score d-flex align-items-center",
            },
            [
              _c("div", {
                staticClass: "score-icon",
              }),
              _c(
                "div",
                {
                  staticClass: "score-text",
                },
                [_vm._v(_vm._s(data.score) + _vm._s(data.description))]
              ),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }